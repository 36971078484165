<template>
  <b-card class="blog-edit">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Copying Protection

        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="CopyProtecationFormvalidate">
      <b-form
        class="mt-2"
        style="width: 60%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="8">
            <label>Partial Copying Prevention</label>
          </b-col><b-col md="4">
            <b-form-radio-group
              v-model="CopyProtecationForm.partial"
              label="saveas"
              class="mb-2"
            >
              <b-form-radio value="on">
                on
              </b-form-radio>
              <b-form-radio value="off">
                off
              </b-form-radio>
            </b-form-radio-group>
          </b-col>
          <b-col md="8">
            <label>Overall Copying Prevention</label>
          </b-col><b-col md="4">
            <b-form-radio-group
              v-model="CopyProtecationForm.general"
              label="saveas"
              class="mb-2"
            >
              <b-form-radio value="on">
                on
              </b-form-radio>
              <b-form-radio value="off">
                off
              </b-form-radio>
            </b-form-radio-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,

  BFormRadio,

  BFormRadioGroup,

  BForm,
  BRow,
  BCol,

  BButton,

} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'

import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'

import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'

export default {
  setup() {
    const form = ref({
      partial: '',
      general: '',

    })
    const CopyProtecationFormvalidate = ref()
    const CopyProtecationForm = reactive(form)

    const getDat = () => {
      store.dispatch('setting/GetcopyProtectiondata').then(response => {
        console.log('GetcopyProtection', response)
        form.value = response.data
      })
    }
    getDat()

    const save = () => {
      //   console.log(CopyProtecationForm)
      const formData = new FormData()

      CopyProtecationFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append('partial', CopyProtecationForm.value.partial)
          formData.append('general', CopyProtecationForm.value.general)

          store.dispatch('setting/copyProtection', formData)
            .then(response => {
              Vue.swal({
                title: ' Updated ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }).catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
    return {

      CopyProtecationForm,

      CopyProtecationFormvalidate,
      form,

      getDat,
      save,
      required,
      email,
    }
  },

  components: {
    BCard,

    ValidationObserver,

    BFormRadio,

    BFormRadioGroup,
    BForm,

    BRow,
    BCol,
    BButton,

  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
